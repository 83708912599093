import React, { ReactElement, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import Page from "../../components/page/Page";
import { clearList, selectTenantSearchLoadingStatus, selectTenantSearchResult } from "../../redux/slices/tenantSlice";
import { LoadingStatus } from "../../redux/slices/sliceHelper";
import { useStrictTranslation } from "../../hooks/useStrictTranslation";
import { organizationPath, pathWithParams, searchOrganizationPath } from "../../utils/paths";
import { searchTenants } from "../../redux/slices/tenantThunks";
import { AccountBalanceRounded } from "@mui/icons-material";
import SearchCount from "./SearchCount";
import SearchSkeleton from "./SearchSkeleton";
import { addBreadcrumb, selectBreadcrumbs } from "../../redux/slices/breadcrumbSlice";
import { BritaTable } from "iq-web-components";
import { TenantSearchResult } from "../../redux/slices/tenantReducer";
import { useSearch } from "../../hooks/useSearch";
import { RowBox } from "../../components/RowBox";
import { Copyable } from "../../components/Copyable";
import { Link as MUILink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const TenantSearch = (): ReactElement => {
  const { t } = useStrictTranslation();
  const tenantList = useAppSelector(selectTenantSearchResult);
  const loadingStatus = useAppSelector(selectTenantSearchLoadingStatus);
  const breadcrumbs = useAppSelector(selectBreadcrumbs);
  const dispatch = useAppDispatch();

  const hasListEntries = tenantList?.length > 0;
  const count = hasListEntries ? tenantList.length : 0;
  const templateColumns = "2fr 1fr 1fr";

  useEffect(() => {
    if (-1 === breadcrumbs.findIndex((breadcrumb) => breadcrumb.path === searchOrganizationPath)) {
      dispatch(addBreadcrumb({ label: t("translation:search_view.tenant_table.title"), path: searchOrganizationPath }));
    }
  }, [breadcrumbs]);

  const handleClear = () => {
    dispatch(clearList());
  };
  const fetch = (searchInput: string) => {
    dispatch(searchTenants({ matches: searchInput }));
  };
  const search = useSearch({ handleClear, fetch });

  return (
    <Page title={t("translation:search_view.tenant_table.title")} icon={AccountBalanceRounded}>
      {search.searchBar}
      <SearchCount count={count} />
      <BritaTable<TenantSearchResult>
        SkeletonTable={() => <SearchSkeleton templateColumns={templateColumns} />}
        loading={loadingStatus === LoadingStatus.LOADING}
        key={"tenants-table"}
        items={tenantList}
        columnOptions={{
          name: {
            CellComponent: ({ item: organization }) => (
              <MUILink
                color="inherit"
                component={RouterLink}
                to={pathWithParams(organizationPath, { organizationId: organization.id })}
                variant="h6"
                underline="hover"
              >
                {organization.name}
              </MUILink>
            ),
            label: t("translation:common.name")
          },
          created: {
            label: t("translation:search_view.tenant_table.created"),
            dateAsTimestamp: true
          },
          id: {
            CellComponent: ({ item: organization }) => <Copyable text={organization.id}>{organization.id}</Copyable>,
            label: t("translation:common.id")
          }
        }}
        RowComponent={({ children }) => <RowBox>{children}</RowBox>}
      />
    </Page>
  );
};

export default TenantSearch;
