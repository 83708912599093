import SkeletonRow from "../../components/SkeletonRow";
import React from "react";

const SearchSkeleton: React.FC<{ templateColumns: string }> = ({ templateColumns }) => {
  return (
    <>
      {[...Array(10)].map((element, index) => (
        <SkeletonRow columnGridStyle={templateColumns} key={`user_search_result_skeletonRow_${index}`} />
      ))}
    </>
  );
};

export default SearchSkeleton;
