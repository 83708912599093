import { User } from "../redux/slices/userReducer";
import SearchSkeleton from "../views/search/SearchSkeleton";
import { RowBox } from "./RowBox";
import { pathWithParams, userPath } from "../utils/paths";
import { BritaTable } from "iq-web-components";
import React from "react";
import { useStrictTranslation } from "../hooks/useStrictTranslation";
import { Copyable } from "./Copyable";
import { Link as RouterLink } from "react-router-dom";
import { Link as MUILink } from "@mui/material";

export interface UserTableProps {
  items: User[];
  loading: boolean;
}

const templateColumns = "1fr 1fr 1fr 2fr";

export const UserTable: React.FC<UserTableProps> = ({ items, loading }) => {
  const { t } = useStrictTranslation();

  return (
    <BritaTable<User>
      SkeletonTable={() => <SearchSkeleton templateColumns={templateColumns} />}
      loading={loading}
      items={items}
      RowComponent={({ children }) => <RowBox>{children}</RowBox>}
      columnOptions={{
        firstName: {
          CellComponent: ({ item: user }) => (
            <MUILink
              color="inherit"
              component={RouterLink}
              to={pathWithParams(userPath, { userId: user.id })}
              underline="hover"
            >
              {user.firstName}
            </MUILink>
          ),
          label: t("translation:common.name")
        },
        lastName: {
          CellComponent: ({ item: user }) => (
            <MUILink
              color="inherit"
              component={RouterLink}
              to={pathWithParams(userPath, { userId: user.id })}
              underline="hover"
            >
              {user.lastName}
            </MUILink>
          ),
          label: t("translation:search_view.user_table.last_name")
        },
        created: {
          label: t("translation:search_view.user_table.created"),
          dateAsTimestamp: true
        },
        mail: {
          CellComponent: ({ item: user }) => <Copyable text={user.mail}>{user.mail} </Copyable>,
          label: t("translation:search_view.user_table.mail")
        },
        id: {
          CellComponent: ({ item: user }) => <Copyable text={user.id}>{user.id}</Copyable>,
          label: t("translation:common.id")
        }
      }}
    />
  );
};
