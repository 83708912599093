import { Link as RouterLink } from "react-router-dom";
import { homePath } from "../../../utils/paths";
import { Box, Typography } from "@mui/material";
import Logo from "../../../components/Logo";
import React from "react";
import { styled } from "@mui/material/styles";
import { useStrictTranslation } from "../../../hooks/useStrictTranslation";
import { clearBreadcrumbs } from "../../../redux/slices/breadcrumbSlice";
import { useAppDispatch } from "../../../redux/hooks";

const PREFIX = "NavigationLogo";

const classes = {
  logo: `${PREFIX}-logo`
};

const Root = styled(Box)(() => ({
  [`& .${classes.logo}`]: {
    width: "117px"
  }
}));

const NavigationLogo: React.FC = () => {
  const { t } = useStrictTranslation();
  const dispatch = useAppDispatch();

  return (
    <RouterLink to={homePath} onClick={() => dispatch(clearBreadcrumbs())} style={{ textDecoration: "none" }}>
      <Root sx={{ backgroundColor: (theme) => theme.palette.primary.main }}>
        <Box
          sx={{
            background: (theme) => theme.palette.background.default,
            borderRadius: "0px 0px 0px 32px",
            color: (theme) => theme.palette.text.primary,
            display: "flex",
            alignItems: "center",
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 3,
            paddingLeft: 2
          }}
        >
          <Logo className={classes.logo} />
          <Typography
            variant="button"
            style={{
              marginLeft: "1rem",
              textTransform: "unset",
              textDecoration: "none"
            }}
          >
            {t("translation:common.app_name_1")}
            <br />
            {t("translation:common.app_name_2").toUpperCase()}
          </Typography>
        </Box>
      </Root>
    </RouterLink>
  );
};

export default NavigationLogo;
