import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import LoadingScreen from "./components/LoadingScreen";
import "./i18n/i18nConfig";
import { msalConfig } from "./authConfig";
import { Provider } from "react-redux";
import { store } from "./redux/store";

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<LoadingScreen />}>
        <App pca={msalInstance} />
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
