import React from "react";
import { Box, BoxProps, Container, Typography } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import PageBreadcrumbs from "./PageBreadcrumbs";
import { FCC } from "iq-web-components";

const BoxWithMargin: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box {...props} sx={{ paddingLeft: 3, paddingRight: 3 }}>
    {children}
  </Box>
);

const Page: FCC<PageProps> = ({ title, id, icon: PageIcon, noMaxWidth, children }) => {
  const ContainerComponent: FCC = noMaxWidth ? BoxWithMargin : Container;
  return (
    <ContainerComponent>
      <Box sx={{ display: "flex", alignItems: "center", paddingTop: 3, paddingBottom: 3 }}>
        {PageIcon && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: (theme) => theme.palette.primary.main,
              width: "50px",
              height: "50px",
              borderRadius: 2,
              marginRight: 2
            }}
          >
            <PageIcon sx={{ color: "white" }} />
          </Box>
        )}
        <Box
          sx={{
            flex: 1
          }}
        >
          <Typography variant="h2">{title}</Typography>
          <PageBreadcrumbs />
        </Box>
        <Box sx={{ alignSelf: "flex-start" }}>
          <Typography>{id}</Typography>
        </Box>
      </Box>
      <Box> {children}</Box>
    </ContainerComponent>
  );
};

export default Page;

type PageProps = {
  title?: string;
  id?: string;
  icon?: SvgIconComponent;
  noMaxWidth?: boolean;
};
