import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Breadcrumb, removeBreadcrumbsAtAndAfter, selectBreadcrumbs } from "../../redux/slices/breadcrumbSlice";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const PREFIX = "PageLink";

const classes = {
  root: `${PREFIX}-root`
};

const StyledLink = styled(Link)(({ theme }) => ({
  [`&.${classes.root}`]: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    "&:hover": { textDecoration: "underline" }
  }
}));

const PageBreadcrumbs: React.FC = () => {
  const breadcrumbs = useAppSelector(selectBreadcrumbs);
  const dispatch = useAppDispatch();

  function getBreadcrumb(breadcrumb: Breadcrumb, index: number, breadcrumbsLength: number) {
    if (index + 1 === breadcrumbsLength) {
      return (
        <Typography key={breadcrumb.path} color="text.primary">
          {breadcrumb.label}
        </Typography>
      );
    } else {
      return (
        <StyledLink
          className={classes.root}
          key={breadcrumb.path}
          onClick={() => dispatch(removeBreadcrumbsAtAndAfter({ path: breadcrumb.path }))}
          to={breadcrumb.path}
        >
          {breadcrumb.label}
        </StyledLink>
      );
    }
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadcrumbs?.map((breadcrumb, index) => getBreadcrumb(breadcrumb, index, breadcrumbs.length))}
    </Breadcrumbs>
  );
};
export default PageBreadcrumbs;
