import { Configuration, RedirectRequest } from "@azure/msal-browser";
import { environment } from "./environment";
import { Configuration as ApiConfiguration } from "iq-apis-support-portal-axios";

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.clientId,
    authority: environment.authority,
    knownAuthorities: [environment.authority],
    redirectUri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ""
    }/`
  }
};

export const loginRequest: RedirectRequest = {
  scopes: environment.scopes
};

export function getAPIConfig(): ApiConfiguration {
  return new ApiConfiguration({
    basePath: environment.basePath
  });
}
