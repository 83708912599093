import i18n from "i18next";
import enTranslation from "./en/translation.json";
import deTranslation from "./de/translation.json";
import { initReactI18next } from "react-i18next";

export const resources = {
  en: {
    translation: enTranslation
  },
  de: {
    translation: deTranslation
  }
};

i18n.use(initReactI18next).init({
  lng: "en",
  resources: resources
});

export default i18n;
