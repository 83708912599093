import React, { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { create } from "jss";
import { Alert, Snackbar, StyledEngineProvider, Theme, ThemeProvider } from "@mui/material";
import jssPreset from "@mui/styles/jssPreset";
import StylesProvider from "@mui/styles/StylesProvider";
import GlobalStyles from "./components/GlobalStyles";
import CookiesNotification from "./components/CookiesNotification";
import {
  homePath,
  organizationPath,
  purityciqPath,
  resourceGroupPath,
  searchDevicesPath,
  searchOrganizationPath,
  searchUsersPath,
  userGroupPath,
  userPath
} from "./utils/paths";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType, IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { createBritaTheme } from "./theme";
import DeviceSearch from "./views/search/DeviceSearch";
import UserSearch from "./views/search/UserSearch";
import TenantSearch from "./views/search/TenantSearch";
import MainLayout from "./layouts/MainLayout/MainLayout";
import { BritaComponentsI18nContext } from "iq-web-components";
import {
  clearErrorMessage,
  clearSuccessMessage,
  selectTenantErrorMessage,
  selectTenantSuccessMessage
} from "./redux/slices/tenantSlice";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { useStrictTranslation } from "./hooks/useStrictTranslation";
import { AccessTokenProvider } from "./AccessTokenContext";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const jss = create({ plugins: [...jssPreset().plugins] });

type AppProps = { pca: IPublicClientApplication };

const UserSearchComponent: React.FC = lazy(() => import("./views/search/UserSearch"));
const UserViewComponent: React.FC = lazy(() => import("./views/user/UserView"));
const PurityCiQViewComponent: React.FC = lazy(() => import("./views/purityciq/PurityCiQView"));
const TenantViewComponent: React.FC = lazy(() => import("./views/organization/TenantView"));
const UserGroupViewComponent: React.FC = lazy(() => import("./views/organization/usergroup/UserGroupView"));
const ResourceGroupViewComponent: React.FC = lazy(() => import("./views/organization/resourcegroup/ResourceGroupView"));

const App: React.FC<AppProps> = ({ pca }: AppProps) => {
  const dispatch = useAppDispatch();
  const theme = createBritaTheme({
    direction: "ltr",
    responsiveFontSizes: true,
    theme: "BRITA"
  });
  const { t } = useStrictTranslation();
  const errorMessage = useAppSelector(selectTenantErrorMessage);
  const successMessage = useAppSelector(selectTenantSuccessMessage);

  return (
    <BrowserRouter>
      <BritaComponentsI18nContext.Provider value={{ locale: "de", dictionary: null }}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <StylesProvider jss={jss}>
              <AccessTokenProvider>
                <MsalProvider instance={pca}>
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginRequest}
                  >
                    <GlobalStyles />
                    <CookiesNotification />
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={!!errorMessage}
                      autoHideDuration={4500}
                      onClose={() => dispatch(clearErrorMessage())}
                    >
                      <Alert onClose={() => dispatch(clearErrorMessage())} severity="error" sx={{ width: "100%" }}>
                        {errorMessage}
                      </Alert>
                    </Snackbar>
                    <Snackbar
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      open={!!successMessage}
                      autoHideDuration={4500}
                      onClose={() => dispatch(clearSuccessMessage())}
                    >
                      <Alert onClose={() => dispatch(clearSuccessMessage())} severity="success" sx={{ width: "100%" }}>
                        {t(`translation:tenant_view.success_message.${successMessage}`)}
                      </Alert>
                    </Snackbar>
                    <Routes>
                      <Route
                        path={homePath}
                        element={
                          <MainLayout>
                            <UserSearchComponent />
                          </MainLayout>
                        }
                      />
                      <Route
                        path={searchUsersPath}
                        element={
                          <MainLayout>
                            <UserSearch />
                          </MainLayout>
                        }
                      />
                      <Route
                        path={searchDevicesPath}
                        element={
                          <MainLayout>
                            <DeviceSearch />
                          </MainLayout>
                        }
                      />
                      <Route
                        path={searchOrganizationPath}
                        element={
                          <MainLayout>
                            <TenantSearch />
                          </MainLayout>
                        }
                      />
                      <Route
                        path={userPath}
                        element={
                          <MainLayout>
                            <UserViewComponent />
                          </MainLayout>
                        }
                      />
                      <Route
                        path={purityciqPath}
                        element={
                          <MainLayout>
                            <PurityCiQViewComponent />
                          </MainLayout>
                        }
                      />
                      <Route
                        path={organizationPath}
                        element={
                          <MainLayout>
                            <TenantViewComponent />
                          </MainLayout>
                        }
                      />
                      <Route
                        path={userGroupPath}
                        element={
                          <MainLayout>
                            <UserGroupViewComponent />
                          </MainLayout>
                        }
                      />
                      <Route
                        path={resourceGroupPath}
                        element={
                          <MainLayout>
                            <ResourceGroupViewComponent />
                          </MainLayout>
                        }
                      />
                    </Routes>
                  </MsalAuthenticationTemplate>
                </MsalProvider>
              </AccessTokenProvider>
            </StylesProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </BritaComponentsI18nContext.Provider>
    </BrowserRouter>
  );
};

export default App;
