import React, { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { drawerWidth, NavigationDrawer } from "./NavBar/NavigationDrawer";

const PREFIX = "MainLayout";

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  contentWrapper: `${PREFIX}-contentWrapper`,
  contentContainer: `${PREFIX}-contentContainer`,
  content: `${PREFIX}-content`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "grid",
    height: "100vh",
    width: "100vw",
    backgroundColor: theme.palette.background.default,
    gridTemplateColumns: `${drawerWidth}px 1fr`,
    gridTemplateRows: "1fr"
  },

  [`& .${classes.content}`]: {
    flex: "1 1 auto",
    height: "100%",
    overflowY: "auto"
  }
}));

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <Root className={classes.root}>
      <NavigationDrawer />
      <div className={classes.content}>{children}</div>
    </Root>
  );
};

export default MainLayout;
