import { ListSubheader, MenuList } from "@mui/material";
import React from "react";
import NavigationMenuItem, { NavigationMenuItemType } from "./NavigationMenuItem";

const NavigationMenu: React.FC<NavigationMenuProps> = ({ menuList }) => {
  const getSubHeader = (menuListItem: NavigationMenuList) => (
    <ListSubheader
      sx={{
        background: (theme) => theme.palette.primary.main,
        color: "#00b1eb"
      }}
    >
      {menuListItem.subHeader}
    </ListSubheader>
  );

  const getMenuItemList = (menuListItem: NavigationMenuList) => {
    const menuItemList = menuListItem.items.map((item) => <NavigationMenuItem item={item} />);
    menuItemList.unshift(getSubHeader(menuListItem));
    return menuItemList;
  };

  return (
    <MenuList
      sx={{
        padding: 3,
        borderTopRightRadius: 1,
        borderRadius: "0px 32px 0px 0px",
        background: (theme) => theme.palette.primary.main,
        height: "100%"
      }}
    >
      {menuList.map((menuListItem) => getMenuItemList(menuListItem))}
    </MenuList>
  );
};

export default NavigationMenu;

type NavigationMenuProps = {
  menuList: Array<NavigationMenuList>;
};

export type NavigationMenuList = {
  subHeader: string;
  items: Array<NavigationMenuItemType>;
};
