import React from "react";
import { Box, IconButton } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { FCC } from "iq-web-components";

interface CopyableProps {
  text: string;
}

export const Copyable: FCC<CopyableProps> = ({ text, children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "&:hover button": {
          opacity: 1
        }
      }}
    >
      <div>{children}</div>
      <IconButton
        sx={{
          opacity: 0,
          transition: "opacity .15s"
        }}
        size={"small"}
        onClick={() => {
          navigator.clipboard.writeText(text);
        }}
      >
        <ContentCopy fontSize={"inherit"} />
      </IconButton>
    </Box>
  );
};
