import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import deviceReducer from "./slices/deviceSlice";
import tenantReducer from "./slices/tenantSlice";
import breadcrumbReducer from "./slices/breadcrumbSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    device: deviceReducer,
    tenant: tenantReducer,
    breadcrumb: breadcrumbReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
