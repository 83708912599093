import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAPIConfig } from "../../authConfig";
import axiosInstance from "../axiosInstance";
import { DeviceApi } from "iq-apis-support-portal-axios";

const deviceApi = new DeviceApi(getAPIConfig(), undefined, axiosInstance);

export const searchDevices = createAsyncThunk(
  "device/search",
  async ({ matches }: DeviceSearchPayload, { rejectWithValue }) => {
    try {
      const responseDevices = await deviceApi?.searchDevices(matches);
      return { devices: responseDevices.data };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

type DeviceSearchPayload = {
  matches: string;
};
