import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import Cookies from "js-cookie";
import { Box, Button, Portal, Typography } from "@mui/material";

const PREFIX = "CookiesNotification";

const classes = {
  root: `${PREFIX}-root`,
  action: `${PREFIX}-action`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 600,
    position: "fixed",
    bottom: 0,
    left: 0,
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    outline: "none",
    zIndex: 2000
  },

  [`& .${classes.action}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black
  }
}));

const CookiesNotification: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    Cookies.set("consent", "true");
    setOpen(false);
  };

  useEffect(() => {
    const consent: string | undefined = Cookies.get("consent");

    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <Root className={classes.root}>
        <Typography variant="body1" color="inherit">
          We use Cookies to ensure that we give you the best experience on our website.
        </Typography>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={handleClose} variant="contained" className={classes.action}>
            I Agree
          </Button>
        </Box>
      </Root>
    </Portal>
  );
};

export default CookiesNotification;
