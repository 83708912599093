import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import _ from "lodash";

const initialState: BreadcrumbState = {
  breadcrumbs: []
};

export const breadcrumbSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    clearBreadcrumbs: (state) => {
      state.breadcrumbs = [];
    },
    addBreadcrumb: (state, action: PayloadAction<{ label: string; path: string }>) => {
      const { label, path } = action.payload;

      state.breadcrumbs.push({ label: label, path: path });
    },
    removeBreadcrumbsAtAndAfter: (state, action: PayloadAction<{ path: string }>) => {
      const { path } = action.payload;

      const clone = _.clone(state.breadcrumbs);
      const index = clone.findIndex((breadcrumb) => breadcrumb.path === path);
      clone.splice(index);
      state.breadcrumbs = clone;
    }
  }
});

export const { clearBreadcrumbs, addBreadcrumb, removeBreadcrumbsAtAndAfter } = breadcrumbSlice.actions;

export const selectBreadcrumbs = (state: RootState): Array<Breadcrumb> => state.breadcrumb.breadcrumbs;

export default breadcrumbSlice.reducer;

export interface BreadcrumbState {
  breadcrumbs: Array<Breadcrumb>;
}

export type Breadcrumb = { label: string; path: string };
