import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../store";
import { LoadingStatus, logRejectedErrorMessage } from "./sliceHelper";
import {
  reduceUser,
  reduceUserDevices,
  reduceUserSearchResult,
  reduceUserTenants,
  User,
  UserDevice,
  UserTenant
} from "./userReducer";
import { getUser, GetUserDetailsPayload, getUserDevices, getUserTenants, searchUsers } from "./userThunks";

const initialState: UserState = {
  searchResult: [],
  user: null,
  devices: [],
  tenants: [],
  loading: {
    searchResult: LoadingStatus.IDLE,
    user: LoadingStatus.IDLE,
    devices: LoadingStatus.IDLE,
    tenants: LoadingStatus.IDLE
  }
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearList: (state) => {
      state.searchResult = [];
    },
    clearUser: (state) => {
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchUsers.pending, (state) => {
        state.loading.searchResult = LoadingStatus.LOADING;
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.loading.searchResult = LoadingStatus.IDLE;
        state.searchResult = reduceUserSearchResult(action.payload);
      })
      .addCase(searchUsers.rejected, (state, action) => {
        state.loading.searchResult = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(getUser.pending, (state) => {
        state.loading.user = LoadingStatus.LOADING;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading.user = LoadingStatus.IDLE;
        state.user = reduceUser(action.payload);
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading.user = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(getUserDevices.pending, (state) => {
        state.loading.devices = LoadingStatus.LOADING;
      })
      .addCase(getUserDevices.fulfilled, (state, action) => {
        state.loading.devices = LoadingStatus.IDLE;
        state.devices = reduceUserDevices(action.payload);
      })
      .addCase(getUserDevices.rejected, (state, action) => {
        state.loading.devices = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(getUserTenants.pending, (state) => {
        state.loading.tenants = LoadingStatus.LOADING;
      })
      .addCase(getUserTenants.fulfilled, (state, action) => {
        state.loading.tenants = LoadingStatus.IDLE;
        state.tenants = reduceUserTenants(action.payload);
      })
      .addCase(getUserTenants.rejected, (state, action) => {
        state.loading.tenants = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      });
  }
});

export const { clearList, clearUser } = userSlice.actions;

export const getUserDetails =
  ({ userId }: GetUserDetailsPayload) =>
  (dispatch: AppDispatch): void => {
    dispatch(getUser({ userId }));
    dispatch(getUserDevices({ userId }));
    dispatch(getUserTenants({ userId }));
  };

export const selectUserSearchResultLoadingStatus = (state: RootState): LoadingStatus =>
  state.user?.loading?.searchResult;
export const selectUserLoadingStatus = (state: RootState): LoadingStatus => state.user?.loading?.user;
export const selectUserDevicesLoadingStatus = (state: RootState): LoadingStatus => state.user?.loading?.devices;
export const selectUserTenantsLoadingStatus = (state: RootState): LoadingStatus => state.user?.loading?.tenants;

export const selectUserSearchResult = (state: RootState): Array<User> => state.user?.searchResult;
export const selectUser = (state: RootState): User => state.user.user;
export const selectUserDevices = (state: RootState): Array<UserDevice> => state.user?.devices;
export const selectUserTenants = (state: RootState): Array<UserTenant> => state.user?.tenants;

export default userSlice.reducer;

export interface UserState {
  searchResult: Array<User>;
  user: User;
  tenants: Array<UserTenant>;
  devices: Array<UserDevice>;
  loading: {
    searchResult: LoadingStatus;
    user: LoadingStatus;
    tenants: LoadingStatus;
    devices: LoadingStatus;
  };
}
