import {
  ApiPurityCiQDesiredDto,
  ApiPurityCiQReportedDto,
  RoleAssignmentDtoDto,
  SupportPortalPurityCiQDto,
  SupportPortalTenantPurityCiQDto,
  TenantInviteDto,
  TenantResourceGroupDto,
  TenantDto,
  TenantUserGroupDto,
  VersionDto
} from "iq-apis-support-portal-axios";
import { getFormattedDate } from "../../utils/timeDate";
import i18n from "../../i18n/i18nConfig";
import { Device, reduceDevice } from "./deviceSlice";
import { reduceUser, User } from "./userReducer";

const NO_DATA_STRING_DEFAULT = "";

export enum UserGroupRoleType {
  RESOURCE,
  RESOURCE_GROUP,
  TENANT
}

export function reduceTenantSearchResult(tenants: Array<TenantDto>): Array<TenantSearchResult> {
  return tenants.map((tenant) => {
    return {
      name: tenant?.name ?? NO_DATA_STRING_DEFAULT,
      created: tenant?.created ? new Date(tenant.created) : null,
      id: tenant?.id ?? NO_DATA_STRING_DEFAULT
    };
  });
}

export function reduceTenant(tenant: TenantDto): Tenant {
  return {
    name: tenant?.name ?? NO_DATA_STRING_DEFAULT,
    created: tenant?.created ? getFormattedDate(tenant.created) : NO_DATA_STRING_DEFAULT,
    id: tenant?.id ?? NO_DATA_STRING_DEFAULT
  };
}

export function reduceTenantInvite(invite: TenantInviteDto): TenantInvite {
  return {
    created: new Date(invite?.created),
    mail: invite?.email,
    isAccepted: invite?.accepted
  };
}

export function reduceTenantUserGroup(userGroup: TenantUserGroupDto): TenantUserGroup {
  return {
    id: userGroup?.id,
    name: userGroup?.name,
    tenantId: userGroup?.tenantId,
    users: userGroup?.users?.map((user) => reduceUser(user)),
    userCount: userGroup?.userCount
  };
}

export function reduceTenantUserGroupRoleAssignment(
  userGroupRoleAssignment: RoleAssignmentDtoDto
): TenantUserGroupRoleAssignment {
  let type: UserGroupRoleType;
  let name: string;
  if (userGroupRoleAssignment?.resource) {
    type = UserGroupRoleType.RESOURCE;
    name = userGroupRoleAssignment.resource.name;
  } else if (userGroupRoleAssignment?.resourceGroup) {
    type = UserGroupRoleType.RESOURCE_GROUP;
    name = userGroupRoleAssignment.resourceGroup.name;
  } else if (userGroupRoleAssignment.objectTenant) {
    type = UserGroupRoleType.TENANT;
    name = userGroupRoleAssignment.objectTenant.name;
  }

  return {
    id: userGroupRoleAssignment.role.id,
    name: name,
    type: type
  };
}

export function reduceTenantResourceGroup(resourceGroup: TenantResourceGroupDto): TenantResourceGroup {
  return {
    id: resourceGroup?.id,
    name: resourceGroup?.name,
    tenantId: resourceGroup?.tenantId,
    deviceCount: resourceGroup?.resourceCount,
    devices: resourceGroup?.resources?.map((resource) => reduceDevice(resource))
  };
}

export function reduceTenantPurityCiQ(supportPortalPurityCiQDto: SupportPortalPurityCiQDto): TenantDevicePurityCiQ {
  const { purityCiQ, tsiUrl } = supportPortalPurityCiQDto;
  const { reported } = purityCiQ;
  return {
    tsiUrl: tsiUrl,
    lastActivity: reported?.lastConnect ? new Date(reported.lastConnect) : null,
    name: purityCiQ?.name,
    id: purityCiQ?.id,
    errorIndicator: purityCiQ?.errors?.length > 0,
    state: reducePurityCiQState(supportPortalPurityCiQDto)
  };
}

export function reduceTenantPurityCiQResult(tenantPurityCiQ: SupportPortalTenantPurityCiQDto): TenantPurityCiQ {
  const { purityCiQ, claimCode } = tenantPurityCiQ;
  const reported = purityCiQ?.reported;
  return {
    id: purityCiQ?.id,
    deviceInfo: {
      name: purityCiQ?.name,
      deviceType: reported?.installedCartridgeDetail,
      claimCode: claimCode,
      installationDate: "", // not yet implemented in api
      lastActivity: reported?.lastConnect ? getFormattedDate(reported.lastConnect) : NO_DATA_STRING_DEFAULT
    },
    state: reducePurityCiQState(tenantPurityCiQ),
    software: getSoftware(reported, purityCiQ?.desired),
    errorLog: purityCiQ?.errors
      ? purityCiQ.errors.map((error) => ({
          id: error,
          description: i18n.t(`translation:purity_error_codes.${error}`)
        }))
      : []
  };
}

function reducePurityCiQState(tenantPurityCiQ: SupportPortalPurityCiQDto): TenantPurityCiQState {
  const { purityCiQ, simCard } = tenantPurityCiQ;
  const reported = purityCiQ?.reported;
  return {
    remainingLifetime: reported?.cartridgeLifeTimeRemaining,
    remainingCapacity: reported?.capacityRemaining,
    waterConsumption: reported?.volumeLastPeriod,
    carbonateHardness: reported?.rawWaterCarbonateHardness,
    flushedCorrectly: reported?.flushedCorrectly,
    remainingDataVolume: simCard?.volume
  };
}

function getSoftware(reported: ApiPurityCiQReportedDto, desired: ApiPurityCiQDesiredDto): TenantPurityCiqSoftware {
  return {
    actual: {
      carbonateHardness: reported?.config?.carbonateHardnessAdjustment ?? NO_DATA_STRING_DEFAULT,
      firmware: formatFirmware(reported?.config?.firmware) ?? NO_DATA_STRING_DEFAULT
    },
    target: {
      carbonateHardness: desired?.config?.carbonateHardnessAdjustment ?? NO_DATA_STRING_DEFAULT,
      firmware: formatFirmware(desired?.config?.firmware) ?? NO_DATA_STRING_DEFAULT
    }
  };
}

function formatFirmware(firmware: VersionDto): string {
  if (!firmware) {
    return null;
  }
  const { major, minor, patch, addition } = firmware;
  const additionStr = addition ? `-${addition}` : "";
  return `${major}.${minor}.${patch}${additionStr}`;
}

export type TenantSearchResult = {
  name: string;
  created: Date;
  id: string;
};

export type Tenant = {
  name: string;
  created: string;
  id: string;
};

export type TenantPurityCiQ = {
  id: string;
  deviceInfo: TenantPurityCiQInfo;
  state: TenantPurityCiQState;
  software: TenantPurityCiqSoftware;
  errorLog: Array<TenantPurityCiqError>;
};

type TenantPurityCiQInfo = {
  name: string;
  deviceType: string;
  claimCode: string;
  installationDate: string;
  lastActivity: string;
};

type TenantPurityCiQState = {
  remainingLifetime: number;
  remainingCapacity: number;
  waterConsumption: number;
  carbonateHardness: number;
  flushedCorrectly: boolean;
  remainingDataVolume: number;
};

type TenantPurityCiqSoftware = {
  actual: TenantPurityCiqSoftwareItem;
  target: TenantPurityCiqSoftwareItem;
};

type TenantPurityCiqSoftwareItem = {
  firmware: string;
  carbonateHardness: string;
};

type TenantPurityCiqError = {
  id: string;
  description: string;
};

export type TenantInvite = {
  created: Date;
  mail: string;
  isAccepted: boolean;
};

export type TenantUserGroup = {
  id: string;
  name: string;
  tenantId: string;
  users?: Array<User>;
  userCount?: number;
};

export type TenantUserGroupRoleAssignment = {
  id: string;
  name: string;
  type: UserGroupRoleType;
};

export type TenantResourceGroup = {
  id: string;
  name: string;
  tenantId: string;
  deviceCount: number;
  devices: Array<Device>;
};

export type TenantDevicePurityCiQ = {
  tsiUrl: string;
  lastActivity: Date;
  name: string;
  id: string;
  errorIndicator: boolean;
  state: TenantPurityCiQState;
};
