import React, { ReactElement, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import Page from "../../components/page/Page";
import {
  clearList,
  Device,
  DeviceType,
  selectDeviceLoadingStatus,
  selectDeviceSearchResult
} from "../../redux/slices/deviceSlice";
import { LoadingStatus } from "../../redux/slices/sliceHelper";
import { useStrictTranslation } from "../../hooks/useStrictTranslation";
import { pathWithParams, purityciqPath, searchDevicesPath } from "../../utils/paths";
import { MemoryRounded } from "@mui/icons-material";
import SearchCount from "./SearchCount";
import SearchSkeleton from "./SearchSkeleton";
import { addBreadcrumb, selectBreadcrumbs } from "../../redux/slices/breadcrumbSlice";
import { BritaTable } from "iq-web-components";
import { TextCellBody } from "../../components/TextCellBody";
import { RowBox } from "../../components/RowBox";
import { DeviceNameCellBody } from "../../components/DeviceNameCellBody";
import { useSearch } from "../../hooks/useSearch";
import { searchDevices } from "../../redux/slices/deviceThunk";
import { Copyable } from "../../components/Copyable";

const DeviceSearch = (): ReactElement => {
  const { t } = useStrictTranslation();
  const deviceList = useAppSelector(selectDeviceSearchResult);
  const loadingStatus = useAppSelector(selectDeviceLoadingStatus);
  const breadcrumbs = useAppSelector(selectBreadcrumbs);
  const handleClear = () => {
    dispatch(clearList());
  };
  const fetch = (searchInput: string) => {
    dispatch(searchDevices({ matches: searchInput }));
  };
  const search = useSearch({ handleClear, fetch });

  const hasListEntries = deviceList?.length > 0;
  const count = hasListEntries ? deviceList.length : 0;
  const templateColumns = "1fr 1fr 1fr 1fr";

  useEffect(() => {
    if (-1 === breadcrumbs.findIndex((breadcrumb) => breadcrumb.path === searchDevicesPath)) {
      dispatch(addBreadcrumb({ label: t("translation:search_view.device_table.title"), path: searchDevicesPath }));
    }
  }, [breadcrumbs]);

  const deviceTypeText: Record<DeviceType, string> = {
    [DeviceType.PURITY_CIQ]: t("translation:purity_ci_q_view.purity_ci_q"),
    [DeviceType.CLAIM_CODE]: t("translation:purity_ci_q_view.claim_code")
  };

  const dispatch = useAppDispatch();

  return (
    <Page title={t("translation:search_view.device_table.title")} icon={MemoryRounded}>
      {search.searchBar}
      <SearchCount count={count} />
      <BritaTable<Device>
        SkeletonTable={() => <SearchSkeleton templateColumns={templateColumns} />}
        loading={loadingStatus === LoadingStatus.LOADING}
        key={"devices-table"}
        items={deviceList}
        columnOptions={{
          name: {
            label: t("translation:common.name"),
            CellComponent: ({ item: device }) => (
              <DeviceNameCellBody
                name={device.name}
                type={device.deviceType}
                link={pathWithParams(purityciqPath, { tenantId: device.tenantId, resourceId: device.id })}
              />
            )
          },
          deviceType: {
            label: t("translation:common.type"),
            CellComponent: ({ item: device }) => <TextCellBody text={deviceTypeText[device.deviceType]} />
          },
          id: {
            label: t("translation:common.id"),
            CellComponent: ({ item: device }) => (
              <Copyable text={device.id}>
                <TextCellBody text={device.id} />
              </Copyable>
            )
          },
          tenantId: {
            CellComponent: ({ item: device }) => (
              <Copyable text={device.tenantId}>
                <TextCellBody text={device.tenantId} />
              </Copyable>
            ),
            label: t("translation:search_view.device_table.tenant_id")
          }
        }}
        RowComponent={({ children }) => <RowBox>{children}</RowBox>}
      />
    </Page>
  );
};

export default DeviceSearch;
