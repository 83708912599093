import {
  EResourceDescriptorDiscriminatorDto,
  UserDeviceDto,
  UserDto,
  UserTenantDto
} from "iq-apis-support-portal-axios";
import { DeviceType } from "./deviceSlice";

const NO_DATA_STRING_DEFAULT = "";

export function reduceUserTenants(userTenantList: Array<UserTenantDto>): Array<UserTenant> {
  return userTenantList.map((userTenant) => {
    return {
      id: userTenant?.id,
      name: userTenant?.tenant?.name ?? NO_DATA_STRING_DEFAULT,
      roles: userTenant?.permissions ?? []
    };
  });
}

export function reduceUserDevices(userDeviceList: Array<UserDeviceDto>): Array<UserDevice> {
  const nestedList = userDeviceList
    .filter((userDevice) => userDevice.deviceType === EResourceDescriptorDiscriminatorDto.PurityCiQ)
    .map((userDevice) => {
      return userDevice.inTenants.map(({ tenant, deviceName }) => ({
        id: userDevice?.deviceId,
        deviceType: DeviceType.PURITY_CIQ,
        tenantId: tenant?.tenantId,
        tenant: tenant?.tenantName ?? NO_DATA_STRING_DEFAULT,
        name: deviceName ?? NO_DATA_STRING_DEFAULT
      }));
    });
  return nestedList.flat();
}

export function reduceUser(user: UserDto): User {
  return {
    lastName: user?.lastName ?? NO_DATA_STRING_DEFAULT,
    firstName: user?.firstName ?? NO_DATA_STRING_DEFAULT,
    name: user?.lastName && user?.firstName ? `${user.firstName} ${user.lastName}` : NO_DATA_STRING_DEFAULT,
    mail: user?.email ?? NO_DATA_STRING_DEFAULT,
    created: user?.created ? new Date(user.created) : null,
    id: user?.userId
  };
}

export function reduceUserSearchResult(users: Array<UserDto>): Array<User> {
  return users.map((userDto) => reduceUser(userDto));
}

export type UserTenant = {
  name: string;
  roles: Array<string>;
  id: string;
};

export type UserDevice = {
  name: string;
  deviceType: DeviceType;
  tenantId: string;
  tenant: string;
  id: string;
};

export type User = {
  lastName: string;
  firstName: string;
  name: string;
  mail: string;
  created?: Date;
  id: string;
};
