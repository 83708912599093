import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  TenantApi,
  TenantPurityCiQApi,
  TenantResourceGroupsApi,
  TenantUserGroupApi
} from "iq-apis-support-portal-axios";
import { getAPIConfig } from "../../authConfig";
import axiosInstance from "../axiosInstance";

const tenantApi = new TenantApi(getAPIConfig(), undefined, axiosInstance);
const tenantResourceGroupsApi = new TenantResourceGroupsApi(getAPIConfig(), undefined, axiosInstance);
const tenantUserGroupApi = new TenantUserGroupApi(getAPIConfig(), undefined, axiosInstance);
const tenantPurityCiQApi = new TenantPurityCiQApi(getAPIConfig(), undefined, axiosInstance);

export const searchTenants = createAsyncThunk(
  "tenant/search",
  async ({ matches }: { matches: string }, { rejectWithValue }) => {
    try {
      const response = await tenantApi?.searchTenants(matches);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getTenant = createAsyncThunk(
  "tenant/getTenant",
  async ({ tenantId }: { tenantId: string }, { rejectWithValue }) => {
    try {
      const response = await tenantApi?.searchTenants(tenantId);
      return response?.data[0];
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getTenantPurityCiQ = createAsyncThunk(
  "tenant/getPurityCiQ",
  async ({ tenantId, resourceId }: { tenantId: string; resourceId: string }, { rejectWithValue }) => {
    try {
      const response = await tenantPurityCiQApi?.getTenantResource(tenantId, resourceId);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getTenantUsers = createAsyncThunk(
  "tenant/getUsers",
  async ({ tenantId }: { tenantId: string }, { rejectWithValue }) => {
    try {
      const response = await tenantApi?.getTenantUsers(tenantId);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getTenantInvites = createAsyncThunk(
  "tenant/getInvites",
  async ({ tenantId }: { tenantId: string }, { rejectWithValue }) => {
    try {
      const response = await tenantApi?.getTenantInvites(tenantId);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createTenantInvite = createAsyncThunk(
  "tenant/createInvite",
  async ({ tenantId, email }: { tenantId: string; email: string }, { rejectWithValue }) => {
    try {
      const response = await tenantApi?.createTenantInvite(tenantId, { email: email });
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getTenantUserGroups = createAsyncThunk(
  "tenant/getUserGroups",
  async ({ tenantId }: { tenantId: string }, { rejectWithValue }) => {
    try {
      const response = await tenantUserGroupApi?.getTenantUserGroups(tenantId);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getTenantUserGroup = createAsyncThunk(
  "tenant/getUserGroup",
  async ({ userGroupId, tenantId }: UserGroupRequestParameters, { rejectWithValue }) => {
    try {
      const response = await tenantUserGroupApi?.getTenantUserGroup(tenantId, userGroupId);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteTenantUserGroupUser = createAsyncThunk(
  "tenant/deleteUserGroupUser",
  async ({ tenantId, userGroupId, userId }: DeleteUserGroupRequestParameters, { rejectWithValue }) => {
    try {
      await tenantUserGroupApi?.deleteUserGroupUser(tenantId, userGroupId, userId);
      return { userId: userId };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createTenantUserGroupUser = createAsyncThunk(
  "tenant/createUserGroupUser",
  async ({ tenantId, userGroupId, userId }: CreateUserGroupRequestParameters, { rejectWithValue }) => {
    try {
      await tenantUserGroupApi?.createUserGroupUser(tenantId, userGroupId, { userId });
      return { userId: userId };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getTenantUserGroupRoleAssignments = createAsyncThunk(
  "tenant/getTenantUserGroupRoleAssignments",
  async ({ userGroupId, tenantId }: UserGroupRequestParameters, { rejectWithValue }) => {
    try {
      const response = await tenantUserGroupApi?.getTenantUserGroupRoleAssignments(tenantId, userGroupId);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getTenantResourceGroups = createAsyncThunk(
  "tenant/getResourceGroups",
  async ({ tenantId }: { tenantId: string }, { rejectWithValue }) => {
    try {
      const response = await tenantResourceGroupsApi?.getTenantResourceGroups(tenantId);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getTenantResourceGroup = createAsyncThunk(
  "tenant/getResourceGroup",
  async ({ tenantId, resourceGroupId }: ResourceGroupRequestParameters, { rejectWithValue }) => {
    try {
      const response = await tenantResourceGroupsApi?.getTenantResourceGroup(tenantId, resourceGroupId);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getTenantPurityCiQs = createAsyncThunk(
  "tenant/getTenantPurityCiQs",
  async ({ tenantId }: { tenantId: string }, { rejectWithValue }) => {
    try {
      const response = await tenantApi?.getTenantPurityCiQs(tenantId);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export type CreateUserGroupRequestParameters = { tenantId: string; userGroupId: string; userId: string };
export type DeleteUserGroupRequestParameters = { tenantId: string; userGroupId: string; userId: string };
export type UserGroupRequestParameters = { tenantId: string; userGroupId: string };
export type ResourceGroupRequestParameters = { tenantId: string; resourceGroupId: string };
