import React from "react";

interface LogoProps {
  [key: string]: unknown;
}

const Logo: React.FC<LogoProps> = ({ ...props }) => {
  return <img alt="Logo" src={`/static/logo.png`} {...props} />;
};

export default Logo;
