import { alpha, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import React, { ReactElement } from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "NavigationMenuItem";

const NavigationMenuItem: React.FC<NavigationMenuItemProps> = ({ item }) => {
  const { id, icon, path, label, isSelected, onClickHandler } = item;

  return (
    <Root>
      <MenuItem
        key={`navigation_menu_item_${id}`}
        component={Link}
        to={path}
        onClick={() => onClickHandler(id)}
        selected={isSelected}
        className={classes.menuItem}
        classes={{ selected: classes.menuItemSelected }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </MenuItem>
    </Root>
  );
};

export default NavigationMenuItem;

type NavigationMenuItemProps = {
  item: NavigationMenuItemType;
};

export type NavigationMenuItemType = {
  id: number;
  icon: ReactElement;
  label: string;
  path: string;
  isSelected: boolean;
  onClickHandler: (value: number) => void;
};

const classes = {
  menuItem: `${PREFIX}-menuItem`,
  menuItemSelected: `${PREFIX}-menuItemSelected`
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.menuItem}`]: {
    borderRadius: theme.spacing(0.5),
    height: "44px",
    color: "#84d0f5",
    ":hover": { backgroundColor: alpha(theme.palette.background.default, 0.04) }
  },

  [`& .${classes.menuItem}.${classes.menuItemSelected}`]: {
    backgroundColor: "#084782"
  }
}));
