import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserApi } from "iq-apis-support-portal-axios";
import { getAPIConfig } from "../../authConfig";
import axiosInstance from "../axiosInstance";

const userApi = new UserApi(getAPIConfig(), undefined, axiosInstance);

export const searchUsers = createAsyncThunk(
  "user/search",
  async ({ matches }: UserSearchPayload, { rejectWithValue }) => {
    try {
      const userListResponse = await userApi?.searchUsers(matches);
      return userListResponse.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUser = createAsyncThunk(
  "user/getUser",
  async ({ userId }: GetUserDetailsPayload, { rejectWithValue }) => {
    try {
      const response = await userApi?.getUser(userId);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserDevices = createAsyncThunk(
  "user/getUserDevices",
  async ({ userId }: GetUserDetailsPayload, { rejectWithValue }) => {
    try {
      const response = await userApi?.getUserDevices(userId);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserTenants = createAsyncThunk(
  "user/getUserTenants",
  async ({ userId }: GetUserDetailsPayload, { rejectWithValue }) => {
    try {
      const response = await userApi?.getUserTenants(userId);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

type UserSearchPayload = {
  matches: string;
};

export type GetUserDetailsPayload = {
  userId: string;
};
