import React from "react";
import { IconButton, InputBase } from "@mui/material";
import { Search } from "react-feather";
import { styled } from "@mui/material/styles";
import { Clear } from "@mui/icons-material";

const SearchBar: React.FC<SearchBarProps> = ({ searchValue, searchHandler }) => {
  return (
    <Root className={classes.root}>
      <InputBase
        startAdornment={
          <Search
            style={{
              width: "1rem",
              marginRight: "8px",
              marginBottom: "2px"
            }}
          />
        }
        value={searchValue}
        className={classes.input}
        placeholder="Search"
        onChange={(event) => searchHandler(event.target.value)}
        endAdornment={
          searchValue && (
            <IconButton onClick={() => searchHandler("")}>
              <Clear />
            </IconButton>
          )
        }
      />
    </Root>
  );
};

export default SearchBar;

type SearchBarProps = {
  searchValue: string;
  searchHandler: (searchValue: string) => void;
};

const PREFIX = "SearchBar";

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    display: "flex",
    height: theme.spacing(5)
  },

  [`& .${classes.input}`]: {
    backgroundColor: "white",
    padding: theme.spacing(1),
    flex: 1,
    borderRadius: theme.spacing(0.5),
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.primary.main
  }
}));
