import { Typography, TypographyProps } from "@mui/material";

export interface TextCellBodyProps extends TypographyProps {
  text: string;
}

export const TextCellBody = ({ text, ...typographyProps }: TextCellBodyProps): JSX.Element => {
  return (
    <Typography variant="body2" {...typographyProps}>
      {text}
    </Typography>
  );
};
