import { colors, responsiveFontSizes, Theme, ThemeOptions } from "@mui/material";
import { softShadows } from "./shadows";
import typography from "./typography";
import { deepmerge } from "@mui/utils";
import { createTheme } from "@mui/material/styles";

type Direction = "ltr" | "rtl";

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  theme?: string;
}

const baseOptions: ThemeOptions = {
  direction: "ltr",
  typography,
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: "hidden"
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.075)"
        }
      }
    }
  }
};

const lightOptions: ThemeOptions = {
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    }
  },
  palette: {
    mode: "light",
    action: {
      active: colors.blueGrey[600]
    },
    background: {
      default: "#f4f6f8",
      paper: colors.common.white
    },
    primary: {
      main: "#00569D"
    },
    secondary: {
      main: "#00b1eb",
      contrastText: colors.common.white
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows: softShadows
};

export const createBritaTheme = (config: ThemeConfig = {}): Theme => {
  const customOption: ThemeOptions = {
    direction: config.direction
  };

  const themeOptions = deepmerge(baseOptions, lightOptions);

  let theme = createTheme(deepmerge(themeOptions, customOption));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
