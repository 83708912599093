import { DescriptionRounded, MemoryRounded } from "@mui/icons-material";
import React from "react";
import { DeviceType } from "../redux/slices/deviceSlice";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface DeviceTypeIconProps {
  type: DeviceType;
  sx?: SxProps<Theme>;
}

export const DeviceTypeIcon: React.FC<DeviceTypeIconProps> = ({ type, sx }) => {
  switch (type) {
    case DeviceType.PURITY_CIQ:
      return <MemoryRounded sx={sx} />;
    case DeviceType.CLAIM_CODE:
      return <DescriptionRounded sx={sx} />;
  }
};
