import React, { useRef, useState } from "react";
import { Tooltip } from "@mui/material";
import SearchBar from "../layouts/MainLayout/TopBar/SearchBar";
import { useStrictTranslation } from "./useStrictTranslation";

export interface UseSearchSettings {
  handleClear: () => void;
  fetch: (searchValue: string) => void;
}

export interface UseSearch {
  searchBar: JSX.Element;
}

const minimumQueryLength = 3;

export const useSearch = ({ handleClear, fetch }: UseSearchSettings): UseSearch => {
  const [searchValue, setSearchValue] = useState("");
  const [searchValueId, setSearchValueId] = useState(0);
  const updateSearchValueId = () => setSearchValueId((previousId) => previousId + 1);
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useStrictTranslation();
  const searchValueIdRef = useRef(searchValueId);
  searchValueIdRef.current = searchValueId;
  const searchHandler = (searchInput: string) => {
    setSearchValue(searchInput);
    const currentId = searchValueId + 1;
    updateSearchValueId();
    setShowTooltip(false);
    setTimeout(() => {
      if (!(currentId === searchValueIdRef.current)) return;
      if (searchInput.length < minimumQueryLength) {
        handleClear();
        setShowTooltip(true);
        return;
      }
      fetch(searchInput);
    }, 1000);
  };

  const searchBar = (
    <>
      <Tooltip
        title={t("translation:search_view.search_query_too_short", { count: minimumQueryLength })}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        open={showTooltip}
        placement={"top"}
      >
        <div>
          <SearchBar searchValue={searchValue} searchHandler={searchHandler} />
        </div>
      </Tooltip>
    </>
  );
  return {
    searchBar
  };
};
