import { styled } from "@mui/material/styles";
import { TableRow, TableRowProps } from "@mui/material";

export const RowBox = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover
  }
}));

export const ClickableRowBox = styled(RowBox)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
    cursor: "pointer"
  }
}));

export interface LinkRowBoxProps extends TableRowProps {
  to: string;
}
