// Organize your paths here.
// If you need to link to a path with params in it,
// use the pathWithParams function!

export const homePath = "/";
export const searchUsersPath = "/search/users";
export const searchDevicesPath = "/search/devices";
export const searchOrganizationPath = "/search/organizations";
export const organizationPath = "/organization/:organizationId/";
export const userPath = "/user/:userId/";
export const userGroupPath = "/tenant/:tenantId/usergroup/:userGroupId/";
export const resourceGroupPath = "/tenant/:tenantId/resourcegroup/:resourceGroupId/";
export const purityciqPath = "/purityciq/:tenantId/:resourceId/";
export const dispenserPath = "/dispenser/:resourceId/";

export const pathWithParams = (path: string, params: Record<string, string> = {}): string => {
  const keys = Object.keys(params);
  return keys.reduce((pathUrl, currKey) => {
    const decoratedUrlParam = `:${currKey}`;
    if (pathUrl.indexOf(decoratedUrlParam) > -1) {
      return pathUrl.replace(decoratedUrlParam, params[currKey]);
    }

    return pathUrl;
  }, path);
};
