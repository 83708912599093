import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EResourceDiscriminatorDto, TenantDeviceDto } from "iq-apis-support-portal-axios";
import { LoadingStatus, logRejectedErrorMessage } from "./sliceHelper";
import { searchDevices } from "./deviceThunk";

const initialState: DeviceState = {
  searchResult: [],
  status: LoadingStatus.IDLE
};

export enum DeviceType {
  PURITY_CIQ,
  CLAIM_CODE
}

export function reduceDeviceSearchResult(devices: Array<TenantDeviceDto>): Array<Device> {
  return devices
    .filter((device) => device.deviceType === EResourceDiscriminatorDto.PurityCiQ)
    .map((device) => reduceDevice(device));
}

export function reduceDevice(device: TenantDeviceDto): Device {
  const NO_DATA_DEFAULT = "-";
  return {
    name: device.deviceName ?? NO_DATA_DEFAULT,
    deviceType: DeviceType.PURITY_CIQ,
    tenantId: device.tenantId ?? NO_DATA_DEFAULT,
    id: device.deviceId ?? NO_DATA_DEFAULT
  };
}

export const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    clearList: (state) => {
      state.searchResult = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchDevices.pending, (state) => {
        state.status = LoadingStatus.LOADING;
      })
      .addCase(searchDevices.fulfilled, (state, action) => {
        state.status = LoadingStatus.IDLE;
        state.searchResult = reduceDeviceSearchResult(action.payload.devices);
      })
      .addCase(searchDevices.rejected, (state, action) => {
        state.status = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      });
  }
});

export const { clearList } = deviceSlice.actions;

export const selectDeviceSearchResult = (state: RootState): Array<Device> => state.device?.searchResult;
export const selectDeviceLoadingStatus = (state: RootState): LoadingStatus => state.device?.status;

export default deviceSlice.reducer;

export interface DeviceState {
  searchResult: Array<Device>;
  status: LoadingStatus;
}

export type Device = {
  name: string;
  deviceType: DeviceType;
  tenantId: string;
  id: string;
};
