import i18n from "../i18n/i18nConfig";
import moment from "moment";

/**
 * Determine whether a given date is before the actual date (happened earlier)
 * @param compareDate Date to compare with actual Date
 */
export function isBeforeActualDate(compareDate: string): boolean {
  const actualDate = new Date();
  const tokenDate = new Date(compareDate);

  return actualDate > tokenDate;
}

/*
 * Determine whether a given date is after the actual date (later in time)
 * @param compareDate Date to compare with actual Date
 */
export function isAfterActualDate(compareDate: string): boolean {
  const actualDate = new Date();
  const tokenDate = new Date(compareDate);

  return actualDate < tokenDate;
}

export function getFormattedDate(dateStr: string): string {
  const date = moment(dateStr);
  return date.format("DD.MM.YYYY HH:mm");
}

// workaround because date is not set right somewhere :/
export function getDateString(date: Date | null | undefined): string {
  const result = date?.toLocaleString(i18n.language, {
    day: "numeric",
    year: "numeric",
    month: "numeric",
    minute: "numeric",
    hour: "numeric"
  });
  if (date instanceof Date) {
    return result ?? "-";
  }
  if (date) {
    return new Date(date as string).toLocaleString(i18n.language, {
      day: "numeric",
      year: "numeric",
      month: "numeric",
      minute: "numeric",
      hour: "numeric"
    });
  }
  return result ?? "-";
}
