import React, { ReactElement, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { clearList, selectUserSearchResult, selectUserSearchResultLoadingStatus } from "../../redux/slices/userSlice";
import Page from "../../components/page/Page";
import { useStrictTranslation } from "../../hooks/useStrictTranslation";
import { LoadingStatus } from "../../redux/slices/sliceHelper";
import { searchUsers } from "../../redux/slices/userThunks";
import { searchUsersPath } from "../../utils/paths";
import { GroupRounded } from "@mui/icons-material";
import SearchCount from "./SearchCount";
import { addBreadcrumb, selectBreadcrumbs } from "../../redux/slices/breadcrumbSlice";
import { UserTable } from "../../components/UserTable";
import { useSearch } from "../../hooks/useSearch";

const UserSearch = (): ReactElement => {
  const { t } = useStrictTranslation();
  const userList = useAppSelector(selectUserSearchResult);
  const loadingStatus = useAppSelector(selectUserSearchResultLoadingStatus);
  const breadcrumbs = useAppSelector(selectBreadcrumbs);
  const dispatch = useAppDispatch();

  const hasListEntries = userList?.length > 0;
  const count = hasListEntries ? userList.length : 0;

  useEffect(() => {
    if (-1 === breadcrumbs.findIndex((breadcrumb) => breadcrumb.path === searchUsersPath)) {
      dispatch(addBreadcrumb({ label: t("translation:search_view.user_table.title"), path: searchUsersPath }));
    }
  }, [breadcrumbs]);
  const handleClear = () => {
    dispatch(clearList());
  };
  const fetch = (searchInput: string) => {
    dispatch(searchUsers({ matches: searchInput }));
  };
  const search = useSearch({ handleClear, fetch });

  return (
    <Page title={t("translation:search_view.user_table.title")} icon={GroupRounded}>
      {search.searchBar}
      <SearchCount count={count} />
      <UserTable items={userList} loading={loadingStatus === LoadingStatus.LOADING} />
    </Page>
  );
};

export default UserSearch;
