import { DeviceType } from "../redux/slices/deviceSlice";
import { DeviceTypeIcon } from "./DeviceTypeIcon";
import { Link as MUILink, Stack } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export interface DeviceNameCellBodyProps {
  name: string;
  link: string;
  type: DeviceType;
}

export const DeviceNameCellBody = ({ name, type, link }: DeviceNameCellBodyProps): JSX.Element => {
  return (
    <Stack direction={"row"}>
      <DeviceTypeIcon
        type={type}
        sx={{
          marginRight: (theme) => theme.spacing(1),
          float: "left"
        }}
      />
      <MUILink color="inherit" component={RouterLink} to={link} variant="h6" underline="hover">
        {name}
      </MUILink>
    </Stack>
  );
};
