import { msalInstance } from "./index";
import { loginRequest } from "./authConfig";
import { createContext, useEffect, useState } from "react";
import { FCC } from "iq-web-components";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const AccessTokenContext = createContext("");

export const AccessTokenProvider: FCC = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const effect = async () => {
      try {
        const { accessToken } = await msalInstance.acquireTokenSilent(loginRequest);
        setAccessToken(accessToken);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          await msalInstance.acquireTokenRedirect(loginRequest);
        }
      }
    };
    effect();
  }, [msalInstance]);

  return <AccessTokenContext.Provider value={accessToken}>{children}</AccessTokenContext.Provider>;
};
