import { Typography } from "@mui/material";
import React from "react";
import { useStrictTranslation } from "../../hooks/useStrictTranslation";

const SearchCount: React.FC<{ count: number }> = ({ count }) => {
  const { t } = useStrictTranslation();

  return (
    <Typography variant={"subtitle2"} sx={{ paddingTop: 1, paddingBottom: 1 }}>{`${count} ${
      count === 1 ? t("translation:common.result") : t("translation:common.results")
    }`}</Typography>
  );
};

export default SearchCount;
