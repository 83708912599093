import React, { ReactElement } from "react";
import { Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";

const SkeletonRow = ({ columnGridStyle }: { columnGridStyle: string }): ReactElement => {
  const skeletons: Array<ReactElement> = [];
  columnGridStyle.split(" ").forEach((value, index) => {
    skeletons.push(<Skeleton key={`skeleton_${index}`} variant={"text"} className={classes.item} />);
  });

  return (
    <Root className={classes.root} columnGridStyle={columnGridStyle}>
      {skeletons}
    </Root>
  );
};
export default SkeletonRow;

const PREFIX = "SkeletonRow";

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`
};

const Root = styled("div", {
  shouldForwardProp: (prop) => prop !== "columnGridStyle"
})<{ columnGridStyle }>(({ columnGridStyle, theme }) => ({
  [`&.${classes.root}`]: {
    display: "grid",
    gridTemplateColumns: columnGridStyle,
    gridTemplateRows: "30px",
    gridGap: theme.spacing(1)
  }
}));
