import { Box, Drawer } from "@mui/material";
import { AccountBalanceRounded, GroupRounded, MemoryRounded } from "@mui/icons-material";
import React, { ReactElement, useState } from "react";
import { useStrictTranslation } from "../../../hooks/useStrictTranslation";
import { searchDevicesPath, searchOrganizationPath, searchUsersPath } from "../../../utils/paths";
import NavigationMenu, { NavigationMenuList } from "./NavigationMenu";
import NavigationLogo from "./NavigationLogo";
import { clearBreadcrumbs } from "src/redux/slices/breadcrumbSlice";
import { useAppDispatch } from "../../../redux/hooks";

export enum MenuItems {
  SEARCH_USERS = 1,
  SEARCH_DEVICES,
  SEARCH_TENANTS
}

export const drawerWidth = 304;

export const NavigationDrawer = (): ReactElement => {
  const [selected, setSelected] = useState<number>(0);
  const { t } = useStrictTranslation();
  const dispatch = useAppDispatch();

  const iconStyle = {
    color: "#84d0f5"
  };

  const handleMenuItemClick = (value) => {
    setSelected(value);
    dispatch(clearBreadcrumbs());
  };

  const menuList: Array<NavigationMenuList> = [
    {
      subHeader: t("translation:common.search"),
      items: [
        {
          id: MenuItems.SEARCH_USERS,
          icon: <GroupRounded sx={iconStyle} />,
          label: t("translation:search_view.search_bar.user"),
          path: searchUsersPath,
          isSelected: selected === MenuItems.SEARCH_USERS,
          onClickHandler: handleMenuItemClick
        },
        {
          id: MenuItems.SEARCH_DEVICES,
          icon: <MemoryRounded sx={iconStyle} />,
          label: t("translation:search_view.search_bar.device"),
          path: searchDevicesPath,
          isSelected: selected === MenuItems.SEARCH_DEVICES,
          onClickHandler: handleMenuItemClick
        },
        {
          id: MenuItems.SEARCH_TENANTS,
          icon: <AccountBalanceRounded sx={iconStyle} />,
          label: t("translation:search_view.search_bar.tenant"),
          path: searchOrganizationPath,
          isSelected: selected === MenuItems.SEARCH_TENANTS,
          onClickHandler: handleMenuItemClick
        }
      ]
    }
  ];

  return (
    <Box>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            border: "none"
          }
        }}
        variant="permanent"
        anchor="left"
      >
        <NavigationLogo />
        <NavigationMenu menuList={menuList} />
      </Drawer>
    </Box>
  );
};
