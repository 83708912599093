import axios from "axios";
import { msalInstance } from "../index";
import { loginRequest } from "../authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(async function (config) {
  try {
    const { accessToken } = await msalInstance.acquireTokenSilent(loginRequest);
    config.headers.Authorization = `Bearer ${accessToken}`;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect(loginRequest);
    }
  }

  return config;
});

export default axiosInstance;
