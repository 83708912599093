import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../store";
import { LoadingStatus, logRejectedErrorMessage } from "./sliceHelper";
import {
  reduceTenant,
  reduceTenantInvite,
  reduceTenantPurityCiQ,
  reduceTenantPurityCiQResult,
  reduceTenantResourceGroup,
  reduceTenantSearchResult,
  reduceTenantUserGroup,
  reduceTenantUserGroupRoleAssignment,
  Tenant,
  TenantDevicePurityCiQ,
  TenantInvite,
  TenantPurityCiQ,
  TenantResourceGroup,
  TenantSearchResult,
  TenantUserGroup,
  TenantUserGroupRoleAssignment
} from "./tenantReducer";
import {
  createTenantInvite,
  createTenantUserGroupUser,
  deleteTenantUserGroupUser,
  getTenant,
  getTenantInvites,
  getTenantPurityCiQ,
  getTenantPurityCiQs,
  getTenantResourceGroup,
  getTenantResourceGroups,
  getTenantUserGroup,
  getTenantUserGroupRoleAssignments,
  getTenantUserGroups,
  getTenantUsers,
  searchTenants,
  UserGroupRequestParameters
} from "./tenantThunks";
import { reduceUser, User } from "./userReducer";

export interface TenantState {
  searchResult: Array<TenantSearchResult>;
  tenant: Tenant;
  purityCiQ: TenantPurityCiQ;
  purityCiQs: Array<TenantDevicePurityCiQ>;
  users: Array<User>;
  invites: Array<TenantInvite>;
  userGroups: Array<TenantUserGroup>;
  userGroup: UserGroup;
  resourceGroups: Array<TenantResourceGroup>;
  resourceGroup: TenantResourceGroup;
  loading: {
    searchResult: LoadingStatus;
    tenant: LoadingStatus;
    purityCiQ: LoadingStatus;
    purityCiQs: LoadingStatus;
    users: LoadingStatus;
    invites: LoadingStatus;
    createInvite: LoadingStatus;
    userGroups: LoadingStatus;
    userGroup: { users: LoadingStatus; roleAssignments: LoadingStatus };
    resourceGroups: LoadingStatus;
    resourceGroup: LoadingStatus;
    createUserGroupUser: LoadingStatus;
    deleteUserGroupUser: Record<string, LoadingStatus>;
  };
  errorMessage?: string;
  successMessageKey?: string;
}

const initialState: TenantState = {
  searchResult: [],
  tenant: null,
  purityCiQ: null,
  purityCiQs: [],
  users: [],
  invites: [],
  userGroups: [],
  userGroup: { id: null, name: null, users: [], roleAssignments: [] },
  resourceGroups: [],
  resourceGroup: null,
  loading: {
    searchResult: LoadingStatus.IDLE,
    tenant: LoadingStatus.IDLE,
    purityCiQ: LoadingStatus.IDLE,
    purityCiQs: LoadingStatus.IDLE,
    users: LoadingStatus.IDLE,
    invites: LoadingStatus.IDLE,
    createInvite: LoadingStatus.IDLE,
    userGroups: LoadingStatus.IDLE,
    userGroup: { users: LoadingStatus.IDLE, roleAssignments: LoadingStatus.IDLE },
    resourceGroups: LoadingStatus.IDLE,
    resourceGroup: LoadingStatus.IDLE,
    createUserGroupUser: LoadingStatus.IDLE,
    deleteUserGroupUser: {}
  },
  errorMessage: null,
  successMessageKey: null
};

export const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    clearList: (state) => {
      state.searchResult = [];
    },
    clearPurityCiQ: (state) => {
      state.purityCiQ = null;
    },
    clearTenant: (state) => {
      state.tenant = null;
    },
    clearUserGroup: (state) => {
      state.userGroup = { id: null, name: null, users: [], roleAssignments: [] };
    },
    clearResourceGroup: (state) => {
      state.resourceGroup = null;
    },
    clearErrorMessage: (state) => {
      state.errorMessage = null;
    },
    clearSuccessMessage: (state) => {
      state.successMessageKey = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchTenants.pending, (state: TenantState) => {
        state.loading.searchResult = LoadingStatus.LOADING;
      })
      .addCase(searchTenants.fulfilled, (state: TenantState, action) => {
        state.loading.searchResult = LoadingStatus.IDLE;
        state.searchResult = reduceTenantSearchResult(action.payload);
      })
      .addCase(searchTenants.rejected, (state: TenantState, action) => {
        state.loading.searchResult = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(getTenant.pending, (state: TenantState) => {
        state.loading.tenant = LoadingStatus.LOADING;
      })
      .addCase(getTenant.fulfilled, (state: TenantState, action) => {
        state.loading.tenant = LoadingStatus.IDLE;
        state.tenant = reduceTenant(action.payload);
      })
      .addCase(getTenant.rejected, (state: TenantState, action) => {
        state.loading.tenant = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(getTenantPurityCiQ.pending, (state: TenantState) => {
        state.loading.purityCiQ = LoadingStatus.LOADING;
      })
      .addCase(getTenantPurityCiQ.fulfilled, (state: TenantState, action) => {
        state.loading.purityCiQ = LoadingStatus.IDLE;
        state.purityCiQ = reduceTenantPurityCiQResult(action.payload);
      })
      .addCase(getTenantPurityCiQ.rejected, (state: TenantState, action) => {
        state.loading.purityCiQ = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(getTenantUsers.pending, (state: TenantState) => {
        state.loading.users = LoadingStatus.LOADING;
      })
      .addCase(getTenantUsers.fulfilled, (state: TenantState, action) => {
        state.loading.users = LoadingStatus.IDLE;
        state.users = action.payload.map((tenantUserDto) => reduceUser(tenantUserDto));
      })
      .addCase(getTenantUsers.rejected, (state: TenantState, action) => {
        state.loading.users = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(getTenantInvites.pending, (state: TenantState) => {
        state.loading.invites = LoadingStatus.LOADING;
      })
      .addCase(getTenantInvites.fulfilled, (state: TenantState, action) => {
        state.loading.invites = LoadingStatus.IDLE;
        state.invites = action.payload.map((tenantInviteDto) => reduceTenantInvite(tenantInviteDto));
      })
      .addCase(getTenantInvites.rejected, (state: TenantState, action) => {
        state.loading.invites = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(getTenantUserGroups.pending, (state: TenantState) => {
        state.loading.userGroups = LoadingStatus.LOADING;
      })
      .addCase(getTenantUserGroups.fulfilled, (state: TenantState, action) => {
        state.loading.userGroups = LoadingStatus.IDLE;
        state.userGroups = action.payload.map((tenantUserGroupDto) => reduceTenantUserGroup(tenantUserGroupDto));
      })
      .addCase(getTenantUserGroups.rejected, (state: TenantState, action) => {
        state.loading.userGroups = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(getTenantUserGroup.pending, (state: TenantState) => {
        state.loading.userGroup.users = LoadingStatus.LOADING;
      })
      .addCase(getTenantUserGroup.fulfilled, (state: TenantState, action) => {
        state.loading.userGroup.users = LoadingStatus.IDLE;
        const { users, id, name } = reduceTenantUserGroup(action.payload);
        state.userGroup.id = id;
        state.userGroup.name = name;
        state.userGroup.users = users;
      })
      .addCase(getTenantUserGroup.rejected, (state: TenantState, action) => {
        state.loading.userGroup.users = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(getTenantUserGroupRoleAssignments.pending, (state: TenantState) => {
        state.loading.userGroup.roleAssignments = LoadingStatus.LOADING;
      })
      .addCase(getTenantUserGroupRoleAssignments.fulfilled, (state: TenantState, action) => {
        state.loading.userGroup.roleAssignments = LoadingStatus.IDLE;
        state.userGroup.roleAssignments = action.payload.map((roleAssignment) =>
          reduceTenantUserGroupRoleAssignment(roleAssignment)
        );
      })
      .addCase(getTenantUserGroupRoleAssignments.rejected, (state: TenantState, action) => {
        state.loading.userGroup.roleAssignments = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(getTenantResourceGroups.pending, (state: TenantState) => {
        state.loading.resourceGroups = LoadingStatus.LOADING;
      })
      .addCase(getTenantResourceGroups.fulfilled, (state: TenantState, action) => {
        state.loading.resourceGroups = LoadingStatus.IDLE;
        state.resourceGroups = action.payload.map((tenantResourceGroup) =>
          reduceTenantResourceGroup(tenantResourceGroup)
        );
      })
      .addCase(getTenantResourceGroups.rejected, (state: TenantState, action) => {
        state.loading.resourceGroups = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(getTenantResourceGroup.pending, (state: TenantState) => {
        state.loading.resourceGroup = LoadingStatus.LOADING;
      })
      .addCase(getTenantResourceGroup.fulfilled, (state: TenantState, action) => {
        state.loading.resourceGroup = LoadingStatus.IDLE;
        state.resourceGroup = reduceTenantResourceGroup(action.payload);
      })
      .addCase(getTenantResourceGroup.rejected, (state: TenantState, action) => {
        state.loading.resourceGroup = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(getTenantPurityCiQs.pending, (state: TenantState) => {
        state.loading.purityCiQs = LoadingStatus.LOADING;
      })
      .addCase(getTenantPurityCiQs.fulfilled, (state: TenantState, action) => {
        state.loading.purityCiQs = LoadingStatus.IDLE;
        state.purityCiQs = action.payload.map((tenantDevicePurityCiQ) => reduceTenantPurityCiQ(tenantDevicePurityCiQ));
      })
      .addCase(getTenantPurityCiQs.rejected, (state: TenantState, action) => {
        state.loading.purityCiQs = LoadingStatus.FAILED;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(createTenantInvite.pending, (state: TenantState) => {
        state.loading.createInvite = LoadingStatus.LOADING;
      })
      .addCase(createTenantInvite.fulfilled, (state: TenantState, action) => {
        state.loading.createInvite = LoadingStatus.IDLE;
        state.successMessageKey = "invited_to_tenant";
        state.invites.unshift(reduceTenantInvite(action.payload));
      })
      .addCase(createTenantInvite.rejected, (state: TenantState, action) => {
        state.loading.createInvite = LoadingStatus.FAILED;
        state.errorMessage = (action.payload as { message: string }).message;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(createTenantUserGroupUser.pending, (state) => {
        state.loading.createUserGroupUser = LoadingStatus.LOADING;
      })
      .addCase(createTenantUserGroupUser.fulfilled, (state, action) => {
        state.loading.createUserGroupUser = LoadingStatus.IDLE;
        const addedUser = state.users.filter((user) => user.id === action.payload.userId)[0];
        state.successMessageKey = "added_to_usergroup";
        state.userGroup.users.push(addedUser);
      })
      .addCase(createTenantUserGroupUser.rejected, (state: TenantState, action) => {
        state.loading.createUserGroupUser = LoadingStatus.FAILED;
        state.errorMessage = (action.payload as { message: string }).message;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      })
      .addCase(deleteTenantUserGroupUser.pending, (state, action) => {
        state.loading.deleteUserGroupUser[action.meta.arg.userId] = LoadingStatus.LOADING;
      })
      .addCase(deleteTenantUserGroupUser.fulfilled, (state, action) => {
        state.loading.deleteUserGroupUser[action.meta.arg.userId] = LoadingStatus.IDLE;
        state.successMessageKey = "deleted_from_usergroup";
        state.userGroup.users = state.userGroup.users.filter((user) => user.id !== action.payload.userId);
      })
      .addCase(deleteTenantUserGroupUser.rejected, (state: TenantState, action) => {
        state.loading.deleteUserGroupUser[action.meta.arg.userId] = LoadingStatus.FAILED;
        state.errorMessage = (action.payload as { message: string }).message;
        logRejectedErrorMessage({ actionName: action.type, payload: action.payload });
      });
  }
});

export const {
  clearList,
  clearPurityCiQ,
  clearTenant,
  clearUserGroup,
  clearResourceGroup,
  clearSuccessMessage,
  clearErrorMessage
} = tenantSlice.actions;

export const loadTenantUserTab =
  ({ tenantId }: { tenantId: string }) =>
  (dispatch: AppDispatch): void => {
    dispatch(
      getTenantUsers({
        tenantId: tenantId
      })
    );
    dispatch(
      getTenantInvites({
        tenantId: tenantId
      })
    );
  };

export const loadUserGroup =
  ({ tenantId, userGroupId }: UserGroupRequestParameters) =>
  (dispatch: AppDispatch): void => {
    dispatch(
      getTenantUserGroup({
        tenantId: tenantId,
        userGroupId: userGroupId
      })
    );
    dispatch(
      getTenantUserGroupRoleAssignments({
        tenantId: tenantId,
        userGroupId: userGroupId
      })
    );
  };

export const loadGroups =
  ({ tenantId }: { tenantId: string }) =>
  (dispatch: AppDispatch): void => {
    dispatch(
      getTenantUserGroups({
        tenantId: tenantId
      })
    );
    dispatch(
      getTenantResourceGroups({
        tenantId: tenantId
      })
    );
  };

export const selectTenantSearchResult = (state: RootState): Array<TenantSearchResult> => state.tenant?.searchResult;
export const selectTenantSearchLoadingStatus = (state: RootState): LoadingStatus => state.tenant?.loading.searchResult;
export const selectTenantPurityCiQLoadingStatus = (state: RootState): LoadingStatus => state.tenant?.loading.purityCiQ;
export const selectTenantPurityCiQListLoadingStatus = (state: RootState): LoadingStatus =>
  state.tenant?.loading.purityCiQs;
export const selectTenantUsersLoadingStatus = (state: RootState): LoadingStatus => state.tenant?.loading.users;
export const selectTenantInvitesLoadingStatus = (state: RootState): LoadingStatus => state.tenant?.loading.invites;
export const selectTenantErrorMessage = (state: RootState): string => state.tenant?.errorMessage;
export const selectTenantSuccessMessage = (state: RootState): string => state.tenant?.successMessageKey;
export const selectTenantLoadingStatus = (state: RootState): LoadingStatus => state.tenant?.loading.tenant;
export const selectUserGroupLoadingStatus = (state: RootState): LoadingStatus => state.tenant?.loading.userGroup.users;
export const selectCreateTenantInvitesLoadingStatus = (state: RootState): LoadingStatus =>
  state.tenant?.loading.createInvite;
export const selectUserGroupRoleAssignmentsLoadingStatus = (state: RootState): LoadingStatus =>
  state.tenant?.loading.userGroup.roleAssignments;
export const selectTenantUserGroupsLoadingStatus = (state: RootState): LoadingStatus =>
  state.tenant?.loading.userGroups;
export const selectTenantResourceGroupsLoadingStatus = (state: RootState): LoadingStatus =>
  state.tenant?.loading.resourceGroups;
export const selectTenantResourceGroupLoadingStatus = (state: RootState): LoadingStatus =>
  state.tenant?.loading.resourceGroup;

export const selectCurrentTenant = (state: RootState): Tenant => state.tenant?.tenant;
export const selectTenantUsers = (state: RootState): Array<User> => state.tenant?.users;
export const selectTenantInvites = (state: RootState): Array<TenantInvite> => state.tenant?.invites;
export const selectTenantUserGroups = (state: RootState): Array<TenantUserGroup> => state.tenant?.userGroups;
export const selectUserGroup = (state: RootState): UserGroup => state.tenant?.userGroup;
export const selectUserGroupUsers = (state: RootState): Array<User> => state.tenant?.userGroup?.users ?? [];
export const selectCreateUserGroupUsersLoadingStatus = (state: RootState): LoadingStatus =>
  state.tenant?.loading?.createUserGroupUser;
export const selectDeleteUserGroupUsersLoadingStatus = (state: RootState): Record<string, LoadingStatus> =>
  state.tenant?.loading?.deleteUserGroupUser;
export const selectUserGroupRoleAssignments = (state: RootState): Array<TenantUserGroupRoleAssignment> =>
  state.tenant?.userGroup?.roleAssignments ?? [];
export const selectTenantResourceGroups = (state: RootState): Array<TenantResourceGroup> =>
  state.tenant?.resourceGroups;
export const selectTenantResourceGroup = (state: RootState): TenantResourceGroup => state.tenant?.resourceGroup;
export const selectCurrentPurityCiQ = (state: RootState): TenantPurityCiQ => state.tenant?.purityCiQ;
export const selectTenantPurityCiQList = (state: RootState): Array<TenantDevicePurityCiQ> => state.tenant?.purityCiQs;

export default tenantSlice.reducer;

export type UserGroup = {
  id: string;
  name: string;
  users: Array<User>;
  roleAssignments: Array<TenantUserGroupRoleAssignment>;
};
